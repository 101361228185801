/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #181818; /* Dark shade for navbar */
  color: white;
}

.navbar-logo a {
  color: #e50914; /* Netflix red for logo */
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  margin-right: 20px;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #e50914; /* Hover effect */
}

/* Media Query for Tablet */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }
  .navbar-links {
    margin-top: 1rem;
  }
}

/* Media Query for Mobile */
@media (max-width: 480px) {
  .navbar {
    padding: 0.8rem 1rem;
  }
  .navbar-logo a {
    font-size: 1.2rem;
  }
  .navbar-links a {
    font-size: 0.9rem;
    margin-right: 15px;
  }
}
