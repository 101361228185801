/* src/styles/Footer.css */
.footer-container {
    background-color: #2D2D2D; /* Deep gray for subtlety, aligning with Apple's elegant dark mode */
    color: #FFFFFF; /* High contrast white text for readability */
    padding: 40px 60px; /* Ample padding for a spacious layout */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* System fonts for Mac and cross-platform compatibility */
    text-align: center; /* Center alignment for simplicity */
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2); /* Subtle top shadow for depth */
    border-top: 1px solid #444444; /* Slight border for visual separation from content */
  }
  
  .footer-links-socials-container {
    display: flex;
    justify-content: space-between; /* Space elements evenly */
    align-items: center;
    flex-wrap: wrap; /* Ensure responsiveness */
    max-width: 1200px; /* Maximum width for content coherence */
    margin: auto; /* Centering in the footer */
  }
  
  .footer-links {
    flex: 1 1 300px; /* Flexibility and basis for responsive behavior */
  }
  
  .footer-socials {
    flex: 1 1 300px; /* Flexibility and basis for responsive behavior */
  }
  
  .footer a {
    color: #007AFF; /* Apple's typical blue for actionable elements */
    text-decoration: none; /* No underlines for a cleaner look */
    padding: 8px 12px; /* Padding for better touch targets */
    transition: color 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .footer a:hover, .footer a:focus {
    text-decoration: underline; /* Underline on hover/focus for accessibility */
    color: #ff9500; /* Highlight color change on interaction */
  }
  
  /* Icon styles */
  .footer .MuiSvgIcon-root {
    color: #FFFFFF; /* Icon in white to match the text */
    margin: 0 8px; /* Space around icons */
    vertical-align: middle; /* Align icons with adjacent text */
    font-size: 24px; /* Slightly larger icons for visibility */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .footer-links-socials-container {
      flex-direction: column; /* Stack on smaller screens */
    }
  
    .footer-links, .footer-socials {
      text-align: center;
      margin-top: 20px;
    }
  
    .footer-links a, .footer-socials a {
      display: inline-block; /* Ensure proper spacing in mobile view */
    }
  }
  