/* General Styles */
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #141414; /* Netflix's dark background */
    color: white;
    line-height: 1.6;
}

a {
    color: #e50914; /* Netflix red for links */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

button {
    background-color: #e50914; /* Netflix red for buttons */
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.2s;
}

button:hover {
    background-color: #f40612;
    transform: scale(1.05);
}

/* Navbar Styles */
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #181818; /* Slightly lighter dark shade */
}

/* Main Section Styles */
.main-section {
    text-align: center;
    padding: 4rem 2rem;
}

.main-section h1 {
    font-size: 2.8rem;
    margin-bottom: 1.5rem;
}

.main-section p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
}

/* Features Section Styles */
.features {
    padding: 4rem 2rem;
    margin-bottom: 6rem;
}

.features h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

/* Testimonials Section Styles */
.testimonials {
    padding: 4rem 2rem;
    background-color: #282828; /* Even lighter dark shade for contrast */
    margin-bottom: 3rem;
}

.testimonials h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

/* Footer Styles */
footer {
    background-color: #181818;
    color: white;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    margin-top: auto; /* Ensure the footer is at the bottom */
}

footer div {
    margin: 0;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
    .main-section h1,
    .features h2,
    .testimonials h2 {
        font-size: 2rem;
    }

    nav,
    footer {
        flex-direction: column;
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .main-section h1,
    .features h2,
    .testimonials h2 {
        font-size: 1.8rem;
    }

    nav,
    footer {
        padding: 1rem;
    }

    nav div, footer div {
        margin-bottom: 0.5rem;
    }

    button {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }
}

/* Sticky Footer Adjustment */
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1; /* This ensures that the main content area expands */
}
